



































































































































































import Breadcrumb from '@/components/Breadcrumb.vue';
import Component, {mixins} from 'vue-class-component';
import GeneralUtil from '@/util/GeneralUtil';
import BoardArticleRead from '@/components/BoardArticleRead.vue';

@Component({
  components: {
    GeneralUtil,
    Breadcrumb,
    BoardArticleRead,
  },
})
export default class NoticePageRead extends GeneralUtil {
  notice_breadcrumb = {
    toplink: 'HOME',
    prevlink: 'ABOUT',
    present: '공지사항',
  };
}
