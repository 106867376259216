








































import Breadcrumb from '@/components/Breadcrumb.vue';
import Component, {mixins} from 'vue-class-component';
import GeneralUtil from '@/util/GeneralUtil';
import {Prop, Ref} from 'vue-property-decorator';
import {BoardArticle, BoardArticleResponse, RepoPage, User} from '@/models';
import AXIOS from '../services/axios-wrapper';
import boardService from '../services/board.service';

import 'quill/dist/quill.core.css';
import 'quill/dist/quill.bubble.css';
import {quillEditor} from 'vue-quill-editor';
@Component({
  components: {
    GeneralUtil,
    Breadcrumb,
    quillEditor,
  },
})
export default class BoardArticleRead extends GeneralUtil {
  @Prop(Number) readonly boardArticleId!: number;

  dto: BoardArticle | null = null;

  editorOption = {
    theme: 'bubble',
  };

  created() {
    this.fetchData();
  }

  async fetchData() {
    try {
      this.showLoadingModal();
      const response = await boardService.getArticleDetails(this.boardArticleId);
      this.dto = response;
    } catch (error) {
      console.error(error);
    } finally {
      this.hideLoadingModal();
    }
  }
}
